.columnTitle {
  margin: 0;
  color: #6e6e6e;
}

.brandRank {
  margin: 0;
}

.rawValue {
  margin: 0 10px 0 0 !important;
  font-weight: 700 !important;
  color: #424141;
}

.rawValueBlur {
  margin: 0 10px 0 0 !important;
  font-weight: 700 !important;
  color: #424141;
  filter: blur(2px);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ratingUnknownValue {
  margin: 0 !important;
  font-weight: 500 !important;
  padding: 2px;
  border-width: 1px;
  border-radius: 3px;
  border-style: solid;

  border-color: #747b9f;
  color: #8990a6;
  background-color: #EFF9EF;
}

.ratingZeroValue {
  margin: 0 !important;
  font-weight: 500 !important;
  padding: 2px;
  border-width: 1px;
  border-radius: 3px;
  border-style: solid;

  border-color: #747b9f;
  color: #8990a6;
  background-color: #EFF9EF;
}

.ratingZeroValue:before {
  content: "⬅ ";
}

.ratingPositiveValue {
  margin: 0 !important;
  font-weight: 500 !important;
  padding: 2px;
  border-width: 1px;
  border-radius: 3px;
  border-style: solid;

  border-color: #6CA672;
  color: #6CA672;
  background-color: #EFF9EF;
}

.ratingPositiveValue:before {
  content: "⬆";
}

.ratingNegativeValue {
  margin: 0 !important;
  font-weight: 500 !important;
  padding: 2px;
  border-width: 1px;
  border-radius: 3px;
  border-style: solid;

  border-color: #B37675;
  color: #B37675;
  background-color: #FBF1F0;
}

.ratingNegativeValue:before {
  content: "⬇";
}

.brandWrapper {
  display: flex;
  align-items: center;

  .brandImage {
    margin-right: 20px;
    max-height: 40px;
  }

  .countryImage {
    width: 20px;
  }

  .brandName {
    margin: 0;
    font-weight: bold !important;
  }

  .catalogLink {
    margin: 0;
    color: #2E95C1;

    &:hover {
      color: #2783a8;
      text-decoration: underline;
    }
  }
}

