.columnTitle {
  margin: 0;
  color: #6e6e6e;
}

.industryRank {
  margin: 0;
}

.industryWrapper {
  display: flex;
  align-items: center;

  .industryImage {
    margin-right: 20px;
    max-height: 40px;
  }

  .countryImage {
    width: 20px;
  }

  .industryName {
    margin: 0;
    font-weight: bold !important;
  }

  .catalogLink {
    margin: 0;
    color: #2E95C1;

    &:hover {
      color: #2783a8;
      text-decoration: underline;
    }
  }
}
