//
// Topbar
//

$kt-header-topbar-item-size: 44px;
$kt-header-topbar-lage-flag-size: 19px;
$kt-header-topbar-user-avatar-size: 34px;
$kt-header-topbar-item-size-mobile: 34px;
$kt-header-topbar-user-avatar-size-mobile: 30px;

// Topbar
.kt-header__topbar {
  display: flex;
  align-items: stretch;
  padding: 0;
  padding-right: 15px;
}
// Item
.kt-header__topbar-item {
  display: flex;
  align-items: stretch;
  margin: 0 0.1rem;

  // Wrapper
  .kt-header__topbar-wrapper {
    cursor: pointer;
    display: flex;
    align-items: stretch;

    .kt-badge.kt-badge--notify {
      position: absolute;
      left: 50%;
      margin-left: -2px;
      top: 14px;
    }
  }

  // Icon
  .kt-header__topbar-icon {
    position: relative;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    height: $kt-header-topbar-item-size;
    width: $kt-header-topbar-item-size;
    cursor: pointer;
    @include kt-transition();

    @include kt-rounded {
      border-radius: $kt-border-radius;
    }

    i {
      @include kt-transition();
      font-size: 1.4rem;
      color: darken(#d1d1e4, 3%);
    }
  }

  // User
  &.kt-header__topbar-item--user {
    .kt-header__topbar-user {
      padding: 0 8px;
      display: flex;
      align-items: center;
      align-self: center;
      justify-content: center;
      height: $kt-header-topbar-item-size;
      cursor: pointer;

      @include kt-rounded {
        border-radius: $kt-border-radius;
      }

      .kt-header__topbar-welcome {
        display: flex;
        align-self: center;
        padding: 0 0.55rem 0 0;
        font-weight: 500;
        font-size: 0.9rem;
        color: #636177;
      }

      .kt-header__topbar-username {
        display: flex;
        align-self: center;
        padding: 0 0.55rem 0 0;
        font-weight: 500;
        font-size: 1rem;
        color: #fff;
      }

      img {
        align-self: center;
        max-height: $kt-header-topbar-user-avatar-size;

        @include kt-rounded {
          border-radius: $kt-border-radius;
        }
      }

      .kt-badge.kt-badge--username {
        height: $kt-header-topbar-user-avatar-size;
        width: $kt-header-topbar-user-avatar-size;

        @include kt-rounded {
          border-radius: $kt-border-radius;
        }
      }
    }
  }

  // Languages
  &.kt-header__topbar-item--langs {
    .kt-header__topbar-icon {
      img {
        @include kt-rounded {
          border-radius: $kt-border-radius;
        }

        width: $kt-header-topbar-lage-flag-size;
      }
    }

    .dropdown-menu {
      .kt-nav__link-icon {
        padding-right: 10px;

        img {
          @include kt-rounded {
            border-radius: $kt-border-radius;
          }

          width: 18px;
        }
      }
    }
  }

  // Hover state
  &:hover,
  &.show {
    .kt-header__topbar-icon {
      @include kt-transition();
      background-color: #f2f3f7 !important;

      i {
        @include kt-transition();
        color: kt-brand-color() !important;
      }
    }

    // User
    &.kt-header__topbar-item--user {
      .kt-header__topbar-user {
        @include kt-transition();
      }
    }
  }
}

// Tablet and mobilemode
@include kt-tablet-and-mobile {
  // Topbar
  .kt-header__topbar {
    display: none;
    padding: 0 kt-get($kt-page-padding, mobile);
    background-color: #fff;
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin-top: -(kt-get($kt-header-config, topbar, height, mobile));
    height: kt-get($kt-header-config, topbar, height, mobile);
    position: absolute;
    left: 0;
    right: 0;
    justify-content: flex-end;

    // Fixed mobile header
    .kt-header-mobile--fixed & {
      position: fixed;
      z-index: kt-get($kt-header-config, base, mobile, self, fixed, zindex) - 1;
    }

    // Topbar shown
    .kt-header__topbar--mobile-on & {
      margin-top: 0;
      transition: all 0.3s ease;
    }

    // Topbar item
    .kt-header__topbar-item {
      margin: 0 2px;
      padding: 0;

      // Wrapper
      .kt-header__topbar-wrapper {
        display: flex;
        align-items: stretch;

        .kt-badge.kt-badge--notify {
          top: 6px;
        }
      }

      // Icon
      .kt-header__topbar-icon {
        height: $kt-header-topbar-item-size-mobile;
        width: $kt-header-topbar-item-size-mobile;

        i {
          font-size: 1.3rem;
        }
      }

      // User
      &.kt-header__topbar-item--user {
        margin-left: 2px;
        margin-right: 0;
        padding: 0;

        .kt-header__topbar-wrapper {
          padding: 0 2px;
          height: auto; //$kt-header-topbar-item-size-mobile;

          .kt-header__topbar-welcome {
            padding: 0 0.35rem 0 0;
            font-size: 0.9rem;
          }

          .kt-header__topbar-username {
            padding: 0 0.55rem 0 0;
            font-size: 0.9rem;
          }

          img {
            max-height: $kt-header-topbar-user-avatar-size-mobile;
          }

          .kt-badge.kt-badge--username {
            height: $kt-header-topbar-user-avatar-size-mobile;
            width: $kt-header-topbar-user-avatar-size-mobile;
          }
        }
      }
    }
  }
}
