.value {
  font-weight: 700 !important;
  color: #424141;
}

.valueBlur {
  font-weight: 700 !important;
  color: #424141;
  filter: blur(2px);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.companyWrapper {
  display: flex;
  align-items: center;

  .companyImage {
    margin-right: 20px;
    max-height: 40px;
  }

  .companyName {
    margin: 0;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }

  .catalogLink {
    margin: 0;
    color: #2E95C1;

    &:hover {
      color: #2783a8;
      text-decoration: underline;
    }
  }
}
